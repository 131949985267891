import { useState } from 'react';
import {
  FormProvider,
  useForm,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { useSignUp } from 'hooks/useSignUp';
import { Loader } from 'components/Loader';
import { LoyaltyCardHeader } from './LoyaltyCardHeader';
import { LoyaltyFormBody } from './LoyaltyFormBody';
import { addLoyaltyOption } from 'types/models/Loyalty';
import { AddLoyaltyCheckboxes } from 'user/components/Loyalty/AddLoyalty/AddLoyaltyCheckboxes';
import { AddLoyaltyButtons } from 'user/components/Loyalty/AddLoyalty/AddLoyaltyButtons';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { AddLoyaltyErrorDialog } from 'user/components/Loyalty/AddLoyalty/AddLoyaltyErrorDialog';
import { useCheckout } from 'contexts/CheckoutContext';
import { useConfig } from 'contexts/ConfigContext';
import { useHistory, useLocation } from 'react-router';
import { WLWOLocationState } from 'types/models/WLWOLocationState';
import { ContextualLoader } from 'components/ContextualLoader';
import { useUserSettings } from 'contexts/UserContext';

interface LoyaltyProps {
  isOnUserScreen?: boolean;
}

export const Loyalty: React.FC<LoyaltyProps> = ({ isOnUserScreen }) => {
  const {
    loyalty: { loyaltySignUpAutomatically },
  } = useConfig();
  const history = useHistory();

  const { formData, isLoadingForms } = useSignUp();
  const { addLoyaltyCard, enrollLoyaltyUser, isVerifying } =
    useLoyaltyRewards();
  const { checkBasket, isFetchingBasket } = useCheckout();
  const { isRegisteringFromCheckout } = useUserSettings();

  const methods = useForm({
    mode: 'all',
  });

  const location = useLocation<WLWOLocationState>();
  const { from } = location.state || { from: { pathname: '/venues' } };

  const showCheckboxes =
    !isOnUserScreen || (isOnUserScreen && loyaltySignUpAutomatically);

  const [checkedChoiceEnum, setCheckedChoiceEnum] = useState(
    !showCheckboxes ? addLoyaltyOption.hasLoyalty : addLoyaltyOption.none,
  );

  const hasLoyalty = checkedChoiceEnum === addLoyaltyOption.hasLoyalty;

  const skipContinue = () => {
    if (loyaltySignUpAutomatically) {
      enrollLoyaltyUser(isOnUserScreen, checkBasket, isRegisteringFromCheckout);
    } else {
      history.push(from.pathname);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (values) => {
    addLoyaltyCard(
      values,
      isOnUserScreen,
      checkBasket,
      isRegisteringFromCheckout,
    );
  };

  if (isLoadingForms || !formData) {
    return <Loader />;
  }

  return (
    <>
      <LoyaltyCardHeader isOnUserScreen={isOnUserScreen} />
      {showCheckboxes ? (
        <AddLoyaltyCheckboxes
          onChange={(option) => setCheckedChoiceEnum(option)}
          checkedOption={checkedChoiceEnum}
        />
      ) : null}
      <FormProvider {...methods}>
        {hasLoyalty && (
          <LoyaltyFormBody loyaltyForm={formData?.linkLoyaltyAccount} />
        )}
        <AddLoyaltyButtons
          checkedOption={checkedChoiceEnum}
          handleConfirm={methods.handleSubmit(onSubmit)}
          handleSkip={skipContinue}
        />
      </FormProvider>
      <AddLoyaltyErrorDialog />
      <ContextualLoader isVisible={isVerifying || isFetchingBasket} />
    </>
  );
};
