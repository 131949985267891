import { useConfig } from 'contexts/ConfigContext';
import { isFuture } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import { useCookies } from 'react-cookie';

interface AuthCookie {
  first_name: string;
  brand_id: number;
  exp: number;
  last_name: string;
  estate_id: number;
  id: number;
  email: string;
}

interface AuthCookieProps {
  authCookie: string;
  deleteAuthCookie: () => void;
  isAuthTokenValid: () => boolean;
  setAuthCookie: (cookie: string) => void;
}

export const useAuthCookie = (): AuthCookieProps => {
  const { client } = useConfig();
  const cookieName = `${client.replace(/\s+/g, '')}--userToken--wo`;
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

  const authCookie = cookies[cookieName];

  const setAuthCookie = (cookie: string) => {
    const decodedToken: AuthCookie = jwtDecode(cookie);
    const expiryDate = new Date(decodedToken.exp * 1000);
    setCookie(cookieName, cookie, { expires: expiryDate });
  };

  const deleteAuthCookie = () => {
    removeCookie(cookieName);
  };

  const isAuthTokenValid = (): boolean => {
    if (authCookie) {
      const decodedToken: AuthCookie = jwtDecode(authCookie);
      const expiryDate = new Date(decodedToken.exp * 1000);
      if (isFuture(expiryDate)) {
        return true;
      } else {
        deleteAuthCookie();
      }
    }
    return false;
  };

  return {
    authCookie,
    deleteAuthCookie,
    isAuthTokenValid,
    setAuthCookie,
  };
};
