import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  GuestLoginButton,
  LoginDivider,
  LoginForm,
  RegistrationButton,
  OTPModal,
} from 'login/components';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useDispatch } from 'react-redux';
import { addNotification } from 'core/actions';
import { WLWOLocationState } from 'types/models/WLWOLocationState';
import { LoginContextualLoader } from './components/ContextualLoader';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useCheckout } from 'contexts/CheckoutContext';
import { useBasket } from 'contexts/BasketContext';
import { LoginFormProps } from 'types/models/User';
import { useGlobalUser } from 'contexts/UserContext';

export const LoginPage: React.FC = () => {
  const { acceptsPayment, accountDeletionOnly, guestCheckoutEnabled } =
    useConfig();
  const {
    login: { loginButtonText },
  } = usePhrases();

  const { isLoggedIn, isLoggingOut, logIn, verifyOTP } = useGlobalUser();
  const { checkBasket, isFetchingBasket } = useCheckout();
  const { basket } = useBasket();

  const dispatch = useDispatch();

  const methods = useForm<LoginFormProps>({ mode: 'onSubmit' });
  const location = useLocation<WLWOLocationState>();
  const {
    from: { pathname },
  } = location.state || { from: { pathname: '/venues' } };

  const isCheckingOut = pathname === '/checkout';

  const showGuestCheckout =
    isCheckingOut &&
    Boolean(guestCheckoutEnabled) &&
    acceptsPayment &&
    basket.length > 0;

  const handleLogIn: SubmitHandler<LoginFormProps> = (values) => {
    logIn(values, pathname, checkBasket);
  };

  const handleOTPSubmit = (otp: string) => {
    verifyOTP(otp, pathname, checkBasket);
  };

  const handleOTPResend = () => {
    logIn(methods.getValues(), pathname, checkBasket);
    dispatch(addNotification('One-time code re-sent', 'success'));
  };

  if (isLoggedIn && !isFetchingBasket && !isLoggingOut) {
    return <Redirect to={pathname} />;
  }
  return (
    <div className="container">
      <div className="login-wrapper">
        <div className="login-grid">
          <div className="login-panel">
            <div className="card">
              <LoginContextualLoader />
              <h2>{loginButtonText}</h2>
              <FormProvider {...methods}>
                <LoginForm handleSubmit={methods.handleSubmit(handleLogIn)} />
              </FormProvider>
              {!accountDeletionOnly ? (
                <>
                  <LoginDivider />
                  <RegistrationButton />
                </>
              ) : null}
              {showGuestCheckout && !accountDeletionOnly ? (
                <>
                  <LoginDivider />
                  <GuestLoginButton />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <OTPModal
        handleSubmit={handleOTPSubmit}
        handleOTPResend={handleOTPResend}
      />
    </div>
  );
};
