import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import { TablesModal } from 'core/components/Modals/TablesModal';
import { TimeslotsModal } from 'core/components/Modals/TimeslotsModal';
import { Loader } from 'components/Loader';
import { MenuGridItems } from 'menu/components/MenuGridItems';
import { MenuContainer } from 'menu/components';
import { StickyBasket } from 'basket/components/Basket';
import { MenuModal } from 'menu/components/MenuModal';
import { UpsellModal } from 'menu/components/MenuModal/UpsellModal';
import { useSalesAreas } from 'contexts/VenueContext';
import { AllergenPrompt } from 'menu/components/Allergens';
import { useTables } from 'contexts/TableContext/TableContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';

interface ParamsProps {
  venueId: string;
  serviceName: string;
}

export const MenuPage: React.FC = () => {
  const params = useParams<ParamsProps>();
  const { path } = useRouteMatch();

  const { selectedSalesArea } = useSalesAreas();
  const { selectedTable } = useTables();
  const { selectedTimeslot } = useTimeslots();

  const venueId: number = parseInt(params.venueId);

  // not selected venue or timeslot or table....back to venue list you go!
  if (!venueId || venueId <= 0 || (!selectedTimeslot && !selectedTable)) {
    return <Redirect to="/?redir=mm_page" />;
  }

  return (
    <>
      <div className="container">
        <div className="multi-menu-panel" data-testid="multi-menu">
          {!selectedSalesArea ? (
            <Loader />
          ) : (
            <>
              <Switch>
                <Route
                  exact
                  path={`${path}`}
                  render={() => (
                    <div className="container">
                      <MenuGridItems />
                    </div>
                  )}
                />
                <Route
                  exact
                  path={`${path}/:menuId/`}
                  render={() => (
                    <div>
                      <MenuContainer />
                    </div>
                  )}
                />
              </Switch>
              <AllergenPrompt />
              <MenuModal />
              <UpsellModal />
              <TablesModal />
              <TimeslotsModal />
            </>
          )}
        </div>
      </div>
      <StickyBasket />
    </>
  );
};
