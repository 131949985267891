import { forwardRef, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {
  ConfirmVoucherApplicationModal,
  ConfirmVoucherRemovalModal,
} from 'checkout/components/Modals';
import {
  VoucherBasketLine,
  Voucher as VoucherType,
} from 'types/models/Vouchers';
import { useDispatch } from 'react-redux';
import { usePhrases, useConfig } from 'contexts/ConfigContext';
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form';
import { VoucherPanelLines, VoucherField, VoucherButton } from './components';
import { removeNotification } from 'core/actions';
import { isCheckVoucher, isVoucherError } from 'types/guards';
import { MdErrorOutline } from 'react-icons/md';
import { Instance as TippyInstance } from 'tippy.js';
import { useHistory } from 'react-router';
import { useCheckout } from 'contexts/CheckoutContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useVouchers } from 'contexts/VoucherContext';
import { useGlobalUser } from 'contexts/UserContext';

export const Voucher = forwardRef<HTMLFormElement>((props, voucherFormRef) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { maxNumberOfVouchers } = useConfig();
  const {
    vouchering: { voucherCallToAction, voucherSupportingText },
    giftCard: { giftCardPhrase },
    loyalty: { rewardPhrase },
  } = usePhrases();
  const { logOut } = useGlobalUser();
  const { handleSubmit, formState, register, setError, clearErrors } = useForm({
    mode: 'onChange',
  });

  const { checkBasket } = useCheckout();
  const { redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { redeemedGiftCards } = useGiftCard();
  const {
    checkVoucher,
    checkVoucherLoading,
    checkVoucherResponse,
    redeemedVouchers,
    resetCheckVoucherResponse,
    voidVoucher,
  } = useVouchers();

  const voucherField = useRef<HTMLInputElement>(null);
  const [tempVoucher, setTempVoucher] = useState<VoucherType>();
  const [voucherToRemove, setVoucherToRemove] = useState<VoucherType>();
  const [showApplyModal, setShowApplyModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { errors } = formState;
  const [errorMessage, setErrorMessage] = useState<string>();

  const hasLoyaltyApplied = redeemedLoyaltyRewards.length > 0;
  const hasGiftCards = redeemedGiftCards.length > 0;

  useEffect(() => {
    if (formState?.errors['voucher']) {
      setErrorMessage(formState.errors['voucher'].message);
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [formState]);

  const handleErrors = (code: number, message: string) => {
    switch (code) {
      case -959:
        logOut(true);

        history.push({
          pathname: '/user/login',
          state: { from: { pathname: '/checkout' } },
        });

        break;
      case -705:
        setError('voucher', {
          type: 'manual',
          message: 'The voucher you tried is not valid.',
        });
        break;
      default:
        setError('voucher', {
          type: 'manual',
          message: message,
        });
        break;
    }
  };

  useEffect(() => {
    if (checkVoucherResponse) {
      if (isCheckVoucher(checkVoucherResponse)) {
        setTempVoucher(checkVoucherResponse.voucher);
        setShowApplyModal(true);
      } else if (isVoucherError(checkVoucherResponse)) {
        setTempVoucher(undefined);
        handleErrors(checkVoucherResponse.code, checkVoucherResponse.detail);
      }
    }

    return () => {
      resetCheckVoucherResponse();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkVoucherResponse]);

  const onSubmit: SubmitHandler<FieldValues> = (formData) => {
    setShowTooltip(false);
    clearErrors();
    setErrorMessage(undefined);
    dispatch(removeNotification());

    if (
      maxNumberOfVouchers !== undefined &&
      redeemedVouchers.length === maxNumberOfVouchers
    ) {
      setError('voucher', {
        type: 'manual',
        message: `The maximum number of vouchers (${maxNumberOfVouchers}) have already been applied.`,
      });
    } else {
      checkVoucher(formData.voucher);
    }
  };

  const handleRemovalClick = (voucherLine: VoucherBasketLine) => {
    setVoucherToRemove(voucherLine.voucher);
    setShowRemoveModal(true);
  };

  const confirmRemoval = (voucher: VoucherType) => {
    dispatch(removeNotification());
    voidVoucher(voucher.voucherCode, checkBasket);
    setVoucherToRemove(undefined);
    setShowRemoveModal(false);
  };

  const cancelVoucherRemoval = () => {
    setVoucherToRemove(undefined);
    setShowRemoveModal(false);
  };

  const handleApplyVoucher = (voucher: VoucherType) => {
    checkBasket(true, undefined, undefined, { voucher });
    setTempVoucher(undefined);
    setShowApplyModal(false);
  };

  const handleCancelVoucherApply = (voucher: VoucherType) => {
    setVoucherToRemove(voucher);
    confirmRemoval(voucher);
    setTempVoucher(undefined);
    setShowApplyModal(false);
  };

  const onCreate = (instance: TippyInstance) => {
    const container = instance.popper;
    container.setAttribute('data-testid', 'voucher-error-tooltip');
  };

  return (
    <div className="card vouchers" data-testid="card-voucher">
      <h3 className="title">{voucherCallToAction}</h3>
      <form
        autoComplete="off"
        className="voucher-form body"
        onSubmit={handleSubmit(onSubmit)}
        ref={voucherFormRef}
      >
        {errorMessage && (
          <Tippy
            content={errorMessage}
            reference={voucherField}
            visible={showTooltip}
            theme="error"
            onCreate={onCreate}
          />
        )}
        <VoucherField register={register} errors={errors} ref={voucherField} />
        <div>
          <VoucherButton
            handleSubmit={handleSubmit(onSubmit)}
            isLoading={checkVoucherLoading}
            isDisabled={hasLoyaltyApplied || hasGiftCards}
          />
        </div>
        {hasLoyaltyApplied || hasGiftCards ? (
          <div className="reward-conflict text-muted">
            <MdErrorOutline size={25} />
            {`Vouchers cannot be applied because the basket contains a ${
              hasLoyaltyApplied ? rewardPhrase : giftCardPhrase
            }.`}
          </div>
        ) : null}
        {voucherSupportingText && (
          <div className="text-muted">{voucherSupportingText}</div>
        )}
      </form>
      <VoucherPanelLines handleRemovalClick={handleRemovalClick} />
      {tempVoucher && (
        <ConfirmVoucherApplicationModal
          show={showApplyModal}
          applyVoucher={handleApplyVoucher}
          cancelVoucherApply={handleCancelVoucherApply}
          voucher={tempVoucher}
        />
      )}
      {voucherToRemove && (
        <ConfirmVoucherRemovalModal
          removeVoucher={confirmRemoval}
          show={showRemoveModal}
          voucher={voucherToRemove}
          cancelVoucherRemoval={cancelVoucherRemoval}
        />
      )}
    </div>
  );
});
