import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import axios, { AxiosRequestConfig } from 'axios';
import {
  setAdditionalInfoError999,
  setSelectedTipPreset,
  setTipAmount,
} from 'checkout/actions';
import { selectTipAmount } from 'checkout/selectors';
import {
  formatCheckBasketResponse,
  formatGiftCardLinesRequest,
  formatGiftCardLinesResponse,
  formatLoyaltyLinesRequest,
  formatLoyaltyLinesResponse,
  formatVoucherLinesRequest,
  getAdditionalInformation,
  getGiftCardsFromError,
  getVouchersFromError,
} from 'checkout/utils';
import { useBasket } from 'contexts/BasketContext';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useTables } from 'contexts/TableContext';
import { useTimeslots } from 'contexts/TimeslotContext';
import { useGlobalUser } from 'contexts/UserContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useVouchers } from 'contexts/VoucherContext';
import { addNotification } from 'core/actions';
import { basketLineToCheckoutLine } from 'menu/utils';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ErrorResponse, MakeBraintreePaymentResponse } from 'types/models';
import { BasketAddition, PromotionLine, TaxLine } from 'types/models/Basket';
import { RedeemedGiftCard } from 'types/models/GiftCard';
import { RedeemedRewardItem } from 'types/models/Loyalty';
import {
  AdditionalInformationFormData,
  AdditionalInformationRequest,
  PaymentDeviceData,
  PaymentNonce,
  PaymentType,
} from 'types/models/Payment';
import {
  CheckBasketData,
  CheckBasketResponse,
  CheckBasketResponseProductLine,
  GiftCardLine,
} from 'types/models/Responses/CheckBasketResponse';
import { PlaceOrderResponse } from 'types/models/Responses/PlaceOrderResponse';
import { Voucher, VoucherBasketLine } from 'types/models/Vouchers';
import { isTimeslotService } from 'venue/utils';

interface CheckBasketRewardsObject {
  loyaltyReward?: RedeemedRewardItem;
  loyaltyRewards?: RedeemedRewardItem[];
}

interface CheckBasketGiftCardsObject {
  giftCard?: RedeemedGiftCard;
  giftCards?: GiftCardLine[];
}

interface CheckBasketVoucherObject {
  voucher?: Voucher;
  vouchers?: VoucherBasketLine[];
}

export type CheckBasketParams = (
  fromLogin?: boolean,
  loyaltyRewards?: CheckBasketRewardsObject,
  giftCards?: CheckBasketGiftCardsObject,
  vouchers?: CheckBasketVoucherObject,
  removedTax?: TaxLine,
) => void;

interface CheckoutContext {
  initialised: boolean;
  checkBasket: CheckBasketParams;
  checkBasketData: CheckBasketData | undefined;
  clearCheckout: () => void;
  clearTaxes: () => void;
  isApplePayEnabled: boolean;
  isApplePayReady: boolean;
  isBasketChecked: boolean;
  isCardReady: boolean;
  isGooglePayEnabled: boolean;
  isGooglePayReady: boolean;
  isPayPalEnabled: boolean;
  isPayPalReady: boolean;
  isFetchingBasket: boolean;
  isSubmittingPayment: boolean;
  orderId: number | undefined;
  paymentDisplayName: string;
  paymentInitError: boolean;
  placeOrder: (formData: AdditionalInformationFormData) => void;
  removedTaxes: number[];
  removeTax: (tax: number) => void;
  setIsFetchingBasket: (status: boolean) => void;
  setIsSubmittingPayment: (status: boolean) => void;
  setOrderId: (orderId: number | undefined) => void;
  setPaymentDisplayName: (name: string) => void;
  setPaymentEnabled: (paymentMethod: PaymentType, status: boolean) => void;
  setPaymentInitError: (status: boolean) => void;
  setPaymentReady: (paymentMethod: PaymentType, status: boolean) => void;
  submitPayment: (
    formData: AdditionalInformationFormData,
    deviceData: PaymentDeviceData,
    nonce: PaymentNonce,
    paymentType: PaymentType,
  ) => void;
}

interface BasketLineContext {
  basketLines: CheckBasketResponseProductLine[];
}

interface CheckoutAdditionsContext {
  basketAdditions: BasketAddition[];
  promotions: PromotionLine[];
}

interface CheckoutDetailsContext {
  basketId: string;
  basketTotal: number;
  braintreeToken: string;
  showWaitTime: boolean;
  waitTime: number;
}

interface PaymentContext {
  isApplePayEnabled: boolean;
  isApplePayReady: boolean;
  isCardReady: boolean;
  isGooglePayEnabled: boolean;
  isGooglePayReady: boolean;
  isPayPalEnabled: boolean;
  isPayPalReady: boolean;
  isSubmittingPayment: boolean;
  paymentDisplayName: string;
  paymentInitError: boolean;
  setIsSubmittingPayment: (status: boolean) => void;
  setPaymentDisplayName: (name: string) => void;
  setPaymentEnabled: (paymentMethod: PaymentType, status: boolean) => void;
  setPaymentInitError: (status: boolean) => void;
  setPaymentReady: (paymentMethod: PaymentType, status: boolean) => void;
}

interface TaxesContext {
  clearTaxes: () => void;
  removedTaxes: number[];
  removeTax: (tax: number) => void;
  taxLines: TaxLine[];
}

interface MakePaymentAdditionalParams {
  userEmailAddress?: string;
  timeslot?: string;
  table?: number;
  additionalInformation?: AdditionalInformationRequest[];
}

// set up context
export const CheckoutContext = createContext<CheckoutContext>({
  initialised: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  checkBasket: () => {},
  checkBasketData: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearCheckout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearTaxes: () => {},
  isApplePayEnabled: false,
  isApplePayReady: false,
  isBasketChecked: false,
  isCardReady: false,
  isGooglePayEnabled: false,
  isGooglePayReady: false,
  isPayPalEnabled: false,
  isPayPalReady: false,
  isFetchingBasket: false,
  isSubmittingPayment: false,
  orderId: undefined,
  paymentDisplayName: '',
  paymentInitError: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  placeOrder: () => {},
  removedTaxes: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeTax: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsFetchingBasket: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsSubmittingPayment: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOrderId: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPaymentDisplayName: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPaymentEnabled: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPaymentInitError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPaymentReady: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submitPayment: () => {},
});

// set up hook
export const useCheckout = (): CheckoutContext => {
  const consumer = useContext(CheckoutContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('CheckoutContextProvider not initialised');
  }

  return consumer;
};

export const useBasketLines = (): BasketLineContext => {
  const consumer = useContext(CheckoutContext);
  if (!consumer.initialised) {
    throw new Error(
      'Checkout Context for Basket Lines hook is not initialised',
    );
  }

  return useMemo(
    () => ({
      basketLines: consumer.checkBasketData?.basketLines ?? [],
    }),
    [consumer.checkBasketData?.basketLines],
  );
};

export const useCheckoutAdditions = (): CheckoutAdditionsContext => {
  const consumer = useContext(CheckoutContext);
  if (!consumer.initialised) {
    throw new Error(
      'Checkout Context for Checkout Additions hook is not initialised',
    );
  }

  return useMemo(
    () => ({
      basketAdditions: consumer.checkBasketData?.basketAdditions ?? [],
      promotions: consumer.checkBasketData?.promotions ?? [],
    }),
    [
      consumer.checkBasketData?.basketAdditions,
      consumer.checkBasketData?.promotions,
    ],
  );
};

export const useCheckoutDetails = (): CheckoutDetailsContext => {
  const consumer = useContext(CheckoutContext);
  if (!consumer.initialised) {
    throw new Error(
      'Checkout Context for Checkout Details hook is not initialised',
    );
  }

  // Is this the right thing to do assigning default values?
  return useMemo(
    () => ({
      basketId: consumer.checkBasketData?.basketId ?? '',
      basketTotal: consumer.checkBasketData?.basketTotal ?? 0,
      braintreeToken: consumer.checkBasketData?.braintreeToken ?? '',
      showWaitTime: consumer.checkBasketData?.showWaitTime ?? false,
      waitTime: consumer.checkBasketData?.waitTime ?? 0,
    }),
    [
      consumer.checkBasketData?.basketId,
      consumer.checkBasketData?.basketTotal,
      consumer.checkBasketData?.braintreeToken,
      consumer.checkBasketData?.showWaitTime,
      consumer.checkBasketData?.waitTime,
    ],
  );
};

export const usePayment = (): PaymentContext => {
  const consumer = useContext(CheckoutContext);
  if (!consumer.initialised) {
    throw new Error('Checkout Context for Payment hook is not initialised');
  }

  return useMemo(
    () => ({
      isApplePayEnabled: consumer.isApplePayEnabled,
      isApplePayReady: consumer.isApplePayReady,
      isCardReady: consumer.isCardReady,
      isGooglePayEnabled: consumer.isGooglePayEnabled,
      isGooglePayReady: consumer.isGooglePayReady,
      isPayPalEnabled: consumer.isPayPalEnabled,
      isPayPalReady: consumer.isPayPalReady,
      isSubmittingPayment: consumer.isSubmittingPayment,
      paymentDisplayName: consumer.paymentDisplayName,
      paymentInitError: consumer.paymentInitError,
      setIsSubmittingPayment: consumer.setIsSubmittingPayment,
      setPaymentDisplayName: consumer.setPaymentDisplayName,
      setPaymentEnabled: consumer.setPaymentEnabled,
      setPaymentInitError: consumer.setPaymentInitError,
      setPaymentReady: consumer.setPaymentReady,
    }),
    [
      consumer.isApplePayEnabled,
      consumer.isApplePayReady,
      consumer.isCardReady,
      consumer.isGooglePayEnabled,
      consumer.isGooglePayReady,
      consumer.isPayPalEnabled,
      consumer.isPayPalReady,
      consumer.isSubmittingPayment,
      consumer.paymentDisplayName,
      consumer.paymentInitError,
      consumer.setIsSubmittingPayment,
      consumer.setPaymentDisplayName,
      consumer.setPaymentEnabled,
      consumer.setPaymentInitError,
      consumer.setPaymentReady,
    ],
  );
};

export const useTaxes = (): TaxesContext => {
  const consumer = useContext(CheckoutContext);
  if (!consumer.initialised) {
    throw new Error('Checkout Context for Taxes hook is not initialised');
  }

  return useMemo(
    () => ({
      clearTaxes: consumer.clearTaxes,
      removeTax: consumer.removeTax,
      removedTaxes: consumer.removedTaxes,
      taxLines: consumer.checkBasketData?.taxes ?? [],
    }),
    [
      consumer.clearTaxes,
      consumer.removeTax,
      consumer.removedTaxes,
      consumer.checkBasketData?.taxes,
    ],
  );
};

interface CheckoutContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const CheckoutContextProvider: React.FC<
  CheckoutContextProviderProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    url,
    checkBasket: checkBasketApi,
    makeBraintreePayment,
    placeOrder: fetchPlaceOrder,
  } = useAPI();

  const { isLoggedIn, logOut, setShouldClearBasket, shouldClearBasket } =
    useGlobalUser();
  const { basket, basketHasChanged, clearBasket } = useBasket();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { selectedTable } = useTables();
  const { selectedTimeslot } = useTimeslots();

  const {
    creditLoyaltyReward,
    redeemedLoyaltyRewards,
    setRedeemedLoyaltyRewards,
    removeAllRewards,
  } = useLoyaltyRewards();
  const {
    creditGiftCard,
    redeemedGiftCards,
    setRedeemedGiftCards,
    setShowGiftCardOverchargeModal,
  } = useGiftCard();

  const { redeemedVouchers, setRedeemedVouchers, voidVoucher } = useVouchers();

  const tipAmount = useSelector(selectTipAmount);

  const [isFetchingBasket, setIsFetchingBasket] = useState(false);
  const [checkBasketData, setCheckBasketData] = useState<
    CheckBasketData | undefined
  >(undefined);
  const [isBasketChecked, setIsBasketChecked] = useState(false);

  // -------- Payment states --------
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const [paymentInitError, setPaymentInitError] = useState(false);
  const [paymentDisplayName, setPaymentDisplayName] = useState('');

  const [isCardReady, setIsCardReady] = useState(false);
  const [isApplePayReady, setIsApplePayReady] = useState(false);
  const [isGooglePayReady, setIsGooglePayReady] = useState(false);
  const [isPayPalReady, setIsPayPalReady] = useState(false);

  const [isApplePayEnabled, setIsApplePayEnabled] = useState(false);
  const [isGooglePayEnabled, setIsGooglePayEnabled] = useState(false);
  const [isPayPalEnabled, setIsPayPalEnabled] = useState(false);
  //------------------------

  const [orderId, setOrderId] = useState<number | undefined>();

  const [removedTaxes, setRemovedTaxes] = useState<number[]>([]);

  const loyaltyErrorCodes = [-257];
  const giftCardErrorCodes = [-853, -873, -8801];
  const voucherErrorCodes = [
    -224, -225, -228, -242, -714, -715, -716, -993, -994, -995, -998, -2241,
  ];

  const requestBasketCheck = (
    loyaltyRewards?: CheckBasketRewardsObject,
    giftCards?: CheckBasketGiftCardsObject,
    vouchers?: CheckBasketVoucherObject,
    removedTax?: TaxLine,
  ) => {
    const timeslotParams = isTimeslotService(selectedService)
      ? {
          timeslot: selectedTimeslot?.time,
        }
      : {};

    const convertedBasketLines = basket.map((line) =>
      basketLineToCheckoutLine(line),
    );

    const loyaltyLines = formatLoyaltyLinesRequest(
      loyaltyRewards?.loyaltyRewards ?? redeemedLoyaltyRewards,
      loyaltyRewards?.loyaltyReward,
    );

    const giftCardLines = formatGiftCardLinesRequest(
      giftCards?.giftCards ?? redeemedGiftCards,
      giftCards?.giftCard,
    );

    const voucherLines = formatVoucherLinesRequest(
      vouchers?.vouchers ?? redeemedVouchers,
      vouchers?.voucher,
    );

    const taxLines = removedTaxes.map((tax) => ({ id: tax, remove: true }));
    if (removedTax) {
      taxLines.push({ id: removedTax.id, remove: true });
    }

    const checkBasketConfig = checkBasketApi();
    const checkBasketOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: checkBasketConfig.headers,
      data: requestBodyFormatter({
        ...checkBasketConfig.body,
        giftCardLines,
        lines: convertedBasketLines,
        loyaltyLines,
        method: checkBasketConfig.method,
        salesAreaId: selectedSalesArea?.id,
        serviceId: selectedService,
        siteId: selectedVenue?.id,
        taxes: taxLines,
        ...timeslotParams,
        voucherLines,
      }),
    };

    return axios(checkBasketOptions);
  };

  const requestBraintreePayment = (
    formData: AdditionalInformationFormData,
    deviceData: PaymentDeviceData,
    nonce: PaymentNonce,
    paymentMethod: PaymentType,
  ) => {
    const additionalParams = getAdditionalParams(formData);

    const makeBraintreePaymentConfig = makeBraintreePayment();
    const makeBraintreePaymentOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: makeBraintreePaymentConfig.headers,
      data: requestBodyFormatter({
        ...makeBraintreePaymentConfig.body,
        method: makeBraintreePaymentConfig.method,
        deviceData,
        nonce,
        paymentMethod,
        basketId: checkBasketData?.basketId,
        salesAreaId: selectedSalesArea?.id,
        siteId: selectedVenue?.id,
        tip: tipAmount,
        ...additionalParams,
      }),
    };

    return axios(makeBraintreePaymentOptions);
  };

  const requestPlaceOrder = (formData: AdditionalInformationFormData) => {
    const additionalParams = getAdditionalParams(formData);

    const fetchPlaceOrderConfig = fetchPlaceOrder();
    const fetchPlaceOrderOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: fetchPlaceOrderConfig.headers,
      data: requestBodyFormatter({
        ...fetchPlaceOrderConfig.body,
        method: fetchPlaceOrderConfig.method,
        basketId: checkBasketData?.basketId,
        salesAreaId: selectedSalesArea?.id,
        siteId: selectedVenue?.id,
        ...additionalParams,
      }),
    };

    return axios(fetchPlaceOrderOptions);
  };

  const checkBasket: CheckBasketParams = (
    fromLoginOrGuestOnly?,
    loyaltyRewards?,
    giftCards?,
    vouchers?,
    removedTax?,
  ) => {
    if (fromLoginOrGuestOnly || isLoggedIn) {
      setIsFetchingBasket(true);
      requestBasketCheck(loyaltyRewards, giftCards, vouchers, removedTax)
        .then((response) => {
          if (response?.data?.code && response?.data?.code !== 200) {
            const errResponse = response.data as ErrorResponse;
            handleError(errResponse, loyaltyRewards, giftCards, vouchers);
          } else {
            const data = response.data as CheckBasketResponse;

            setCheckBasketData(formatCheckBasketResponse(data));
            setRedeemedGiftCards(
              formatGiftCardLinesResponse(data?.giftCardLines),
            );
            setRedeemedVouchers(data?.voucherLines ?? []);
            setRedeemedLoyaltyRewards(
              formatLoyaltyLinesResponse(data?.loyaltyLines),
            );
            if (removedTax) {
              removeTax(removedTax.id);
            }
            setIsBasketChecked(true);
            history.push('/checkout');
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'Something went wrong verifying your basket. Please try again',
              'danger',
            ),
          );
        })
        .finally(() => {
          setIsFetchingBasket(false);
        });
    } else {
      history.push('/checkout');
    }
  };

  const submitPayment = (
    formData: AdditionalInformationFormData,
    deviceData: PaymentDeviceData,
    nonce: PaymentNonce,
    paymentType: PaymentType,
  ) => {
    setIsSubmittingPayment(true);
    requestBraintreePayment(formData, deviceData, nonce, paymentType)
      .then((response) => {
        if (response?.data?.code && response?.data?.code !== 200) {
          const errResponse = response.data as ErrorResponse;
          // Sharing the checkBasket error handling but think this is ok
          handleError(errResponse);
        } else {
          const data = response.data as MakeBraintreePaymentResponse;
          setOrderId(data.accountNumber);

          const pathname =
            isLoggedIn || !formData.email
              ? '/complete'
              : `/complete/${encodeURIComponent(formData.email)}`;

          history.push({
            pathname,
            state: { from: { pathname: '/checkout' } },
          });
        }
      })
      .catch(() => {
        // we don't handle this scenario just now - show a generic error message?
      })
      .finally(() => {
        setIsSubmittingPayment(false);
      });
  };

  // For payment disabled - very similar to submitPayment but want to keep it seperate
  const placeOrder = (formData: AdditionalInformationFormData) => {
    setIsSubmittingPayment(true);
    requestPlaceOrder(formData)
      .then((response) => {
        if (response?.data?.code && response?.data?.code !== 200) {
          const errResponse = response.data as ErrorResponse;
          handleError(errResponse);
        } else {
          const data = response.data as PlaceOrderResponse;
          setOrderId(data.accountNumber);

          const pathname =
            isLoggedIn || !formData.email
              ? '/complete'
              : `/complete/${encodeURIComponent(formData.email)}`;

          history.push({
            pathname,
            state: { from: { pathname: '/checkout' } },
          });
        }
      })
      .catch(() => {
        // we don't handle this scenario just now - show a generic error message?
      })
      .finally(() => {
        setIsSubmittingPayment(false);
      });
  };

  const getAdditionalParams = (formData: AdditionalInformationFormData) => {
    const timeslotService = isTimeslotService(selectedService);
    const additionalInfo = getAdditionalInformation(formData);
    const additionalParams: MakePaymentAdditionalParams = {};

    if (!isLoggedIn) {
      additionalParams.userEmailAddress = formData.email;
    }

    if (timeslotService) {
      additionalParams.timeslot = selectedTimeslot?.time;
      if (additionalInfo.length > 0) {
        additionalParams.additionalInformation = additionalInfo;
      }
    } else {
      additionalParams.table = selectedTable?.number;
    }

    return additionalParams;
  };

  const handleError = (
    response: ErrorResponse,
    loyaltyRewards?: CheckBasketRewardsObject,
    giftCards?: CheckBasketGiftCardsObject,
    vouchers?: CheckBasketVoucherObject,
  ) => {
    dispatch(addNotification(response.detail, 'danger', response.code));

    if (giftCards || giftCardErrorCodes.includes(response.code)) {
      handleGiftCardError(response.code, response.detail, giftCards);
    } else if (loyaltyRewards || loyaltyErrorCodes.includes(response.code)) {
      handleLoyaltyError(response.code, loyaltyRewards);
    } else if (vouchers || voucherErrorCodes.includes(response.code)) {
      handleVoucherError(response.code, response.detail, vouchers);
    } else if (response.code === -999) {
      dispatch(setAdditionalInfoError999(response.detail));
    }

    // Handle loyalty, gift card checks first before pushing to login page
    if (response.code === -959) {
      logOut(true);
      history.push({
        pathname: '/user/login',
        state: { from: { pathname: '/checkout' } },
      });
    }
  };

  const handleGiftCardError = (
    errorCode: number,
    errorMessage: string,
    giftCards?: CheckBasketGiftCardsObject,
  ) => {
    if (errorCode === -873) {
      setShowGiftCardOverchargeModal(true);
    } else if (errorCode === -8801) {
      dispatch(
        addNotification(
          'Unfortunately there has been a problem with one or more of your gift cards and all gift cards have been removed from the basket.',
          'danger',
          errorCode,
        ),
      );
      setRedeemedGiftCards([]);
      checkBasket(true, undefined, { giftCards: [] });
    }

    // If the error has been triggered while passing in a gift card we need to credit it back first
    if (giftCards?.giftCard && errorCode !== -853) {
      creditGiftCard(
        giftCards.giftCard.giftCardId,
        giftCards.giftCard.transactionId,
        giftCards.giftCard.amount,
      );
    }

    // This is a catch as the gift card state hasn't updated, we use the first one if the erro has come from a gift card action
    const giftCardsToUse = giftCards?.giftCards ?? redeemedGiftCards;

    if (errorCode === -853 && giftCardsToUse.length > 0) {
      // Remove any gift cards that have timed out
      const giftCardNumbersToRemove = getGiftCardsFromError(errorMessage);
      const filteredGiftCardArray = giftCardsToUse.filter(
        (gc) => !giftCardNumbersToRemove.includes(gc.transactionId),
      );
      setRedeemedGiftCards(filteredGiftCardArray);

      // calls checkBasket again with the current gift card if there is one present
      checkBasket(true, undefined, {
        giftCard: giftCards?.giftCard,
        giftCards: filteredGiftCardArray,
      });
    }
  };

  const handleLoyaltyError = (
    errorCode: number,
    loyaltyRewards?: CheckBasketRewardsObject,
  ) => {
    if (loyaltyRewards?.loyaltyReward) {
      // Credits and voids the loyalty reward that has been added
      creditLoyaltyReward(
        loyaltyRewards.loyaltyReward.transactionId,
        loyaltyRewards.loyaltyReward.rewardId,
        loyaltyRewards.loyaltyReward.rewardType,
        loyaltyRewards.loyaltyReward.quantity,
      );
    }

    if (errorCode === -257 && pathname.includes('checkout')) {
      // Credit all rewards and call checkBasket on the last reward
      removeAllRewards(checkBasket);
    }
  };

  const handleVoucherError = (
    errorCode: number,
    errorMessage: string,
    vouchers?: CheckBasketVoucherObject,
  ) => {
    if (vouchers?.voucher) {
      voidVoucher(vouchers.voucher.voucherCode);
    }

    const vouchersToUse = vouchers?.vouchers ?? redeemedVouchers;

    if (errorCode === -714 && vouchersToUse.length > 0) {
      // Remove any vouchers that have timed out
      const vouchersToRemove = getVouchersFromError(errorMessage);
      const filteredVouchersArray = vouchersToUse.filter(
        (v) => !vouchersToRemove.includes(v.VoucherCode),
      );
      setRedeemedVouchers(filteredVouchersArray);

      // calls checkBasket again with the current voucher if there is one present
      checkBasket(true, undefined, undefined, {
        voucher: vouchers?.voucher,
        vouchers: filteredVouchersArray,
      });
    }
  };

  const clearCheckout = () => {
    setCheckBasketData(undefined);
    setOrderId(undefined);
    setRedeemedGiftCards([]);
    setRedeemedLoyaltyRewards([]);
    setRedeemedVouchers([]);
    setRemovedTaxes([]);
  };

  const setPaymentEnabled = (paymentMethod: PaymentType, status: boolean) => {
    switch (paymentMethod) {
      case PaymentType.ApplePay:
        setIsApplePayEnabled(status);
        break;
      case PaymentType.GooglePay:
        setIsGooglePayEnabled(status);
        break;
      case PaymentType.PayPal:
        setIsPayPalEnabled(status);
    }
  };

  const setPaymentReady = (paymentMethod: PaymentType, status: boolean) => {
    switch (paymentMethod) {
      case PaymentType.CreditCard:
        setIsCardReady(status);
        break;
      case PaymentType.ApplePay:
        setIsApplePayReady(status);
        break;
      case PaymentType.GooglePay:
        setIsGooglePayReady(status);
        break;
      case PaymentType.PayPal:
        setIsPayPalReady(status);
        break;
    }
  };

  const clearTaxes = () => {
    setRemovedTaxes([]);
  };

  const removeTax = (tax: number) => {
    setRemovedTaxes((prevTaxes) => [...prevTaxes, tax]);
  };

  useEffect(() => {
    if (basketHasChanged) {
      setIsBasketChecked(false);
      if (basket.length === 0) {
        clearTaxes();
        setCheckBasketData(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketHasChanged]);

  // This flag is triggered on user logout to clear the basket and checkout
  useEffect(() => {
    if (shouldClearBasket) {
      setShouldClearBasket(false);
      clearBasket();
      clearCheckout();
      // TODO - move this into a context
      dispatch(setTipAmount(0));
      dispatch(setSelectedTipPreset(0));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearBasket]);

  return (
    <CheckoutContext.Provider
      value={{
        initialised: true,
        checkBasket,
        checkBasketData,
        clearCheckout,
        clearTaxes,
        isApplePayEnabled,
        isApplePayReady,
        isBasketChecked,
        isCardReady,
        isGooglePayEnabled,
        isGooglePayReady,
        isPayPalEnabled,
        isPayPalReady,
        isFetchingBasket,
        isSubmittingPayment,
        orderId,
        paymentDisplayName,
        paymentInitError,
        placeOrder,
        removedTaxes,
        removeTax,
        setIsFetchingBasket,
        setIsSubmittingPayment,
        setOrderId,
        setPaymentDisplayName,
        setPaymentEnabled,
        setPaymentInitError,
        setPaymentReady,
        submitPayment,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
