import { useEffect } from 'react';
import { Col, Panel } from 'react-bootstrap';
import { TimeslotsModal } from 'core/components/Modals/TimeslotsModal';
import { TablesModal } from 'core/components/Modals/TablesModal';
import { OrderHistoryTable } from 'order-history/components/OrderHistoryTable';
import { Sidebar } from 'user/components/LoggedInUserComponents';
import { useVenues } from 'contexts/VenueContext';
import { useOrderHistory } from 'contexts/OrderHistoryContext';

export const OrderHistoryPage: React.FC = () => {
  const { venues, fetchVenueSummary, fetchVenues } = useVenues();
  const { fetchOrders, orders } = useOrderHistory();

  useEffect(() => {
    fetchOrders();
    fetchVenues();
    fetchVenueSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <Sidebar />
      <Col sm={9}>
        <Panel>
          <h2>Order History</h2>
          <OrderHistoryTable orders={orders} venues={venues} />
        </Panel>
      </Col>
      <TimeslotsModal />
      <TablesModal />
    </div>
  );
};
