import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import axios, { AxiosRequestConfig } from 'axios';
import { addNotification } from 'core/actions';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Coordinates } from 'types';
import { isGetVenueSummaryResponse, isVenueResponse } from 'types/guards';
import {
  CombinedVenue,
  ErrorResponse,
  Service,
  VenueHome,
  VenueSalesArea,
  VenueSummaryResponse,
  VenuesResponse,
} from 'types/models';
import { GetHomeResponse } from 'types/models/Responses/GetHomeResponse';

interface VenueContext {
  initialised: boolean;
  venues: CombinedVenue[];
  venueHomeData: VenueHome | undefined;
  selectedVenue: CombinedVenue | undefined;
  setSelectedVenue: (venue: CombinedVenue | undefined) => void;
  fetchVenues: (venueId?: number) => void;
  fetchVenueSummary: (coordinates?: Coordinates, venueId?: number) => void;
  fetchVenueHome: (venueId: number) => void;
  isLoadingVenues: boolean;
  services: Service[];
  selectedService: number;
  setSelectedService: (serviceId: number) => void;
  selectedSalesArea: VenueSalesArea | undefined;
  setSelectedSalesArea: (salesArea: VenueSalesArea | undefined) => void;
}

interface ServiceContext {
  services: Service[];
  selectedService: number;
  setSelectedService: (service: number) => void;
}

interface SalesAreaContext {
  salesAreas: VenueSalesArea[] | undefined;
  selectedSalesArea: VenueSalesArea | undefined;
  setSelectedSalesArea: (salesArea: VenueSalesArea | undefined) => void;
}

export const VenueContext = createContext<VenueContext>({
  initialised: false,
  venues: [],
  venueHomeData: undefined,
  selectedVenue: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedVenue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchVenues: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchVenueSummary: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchVenueHome: () => {},
  isLoadingVenues: false,
  services: [],
  selectedService: -1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedService: () => {},
  selectedSalesArea: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedSalesArea: () => {},
});

export const useVenues = (): VenueContext => {
  const consumer = useContext(VenueContext);
  if (!consumer.initialised) {
    throw new Error('Venue Context Provider not initialised');
  }
  return consumer;
};

export const useServices = (): ServiceContext => {
  const consumer = useContext(VenueContext);
  if (!consumer.initialised) {
    throw new Error('Venue Context for Services hook is not initialised');
  }
  return {
    services: consumer.services,
    selectedService: consumer.selectedService,
    setSelectedService: consumer.setSelectedService,
  };
};

export const useSalesAreas = (): SalesAreaContext => {
  const consumer = useContext(VenueContext);
  if (!consumer.initialised) {
    throw new Error('Venue Context for Sales Area hook is not initialised');
  }
  return {
    salesAreas: consumer.selectedVenue?.salesArea,
    selectedSalesArea: consumer.selectedSalesArea,
    setSelectedSalesArea: consumer.setSelectedSalesArea,
  };
};

interface VenueContextProviderProps {
  children: React.ReactNode;
}

export const VenueContextProvider: React.FC<VenueContextProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { url, venues: getVenues, getVenueSummary, getVenueHome } = useAPI();
  const { pathname } = useLocation();
  const history = useHistory();

  // API state - loading and responses
  const [fetchingVenues, setFetchingVenues] = useState(false);
  const [fetchingVenueSummary, setFetchingVenueSummary] = useState(false);
  const [fetchingVenueHome, setFetchingVenueHome] = useState(false);
  const [venuesData, setVenuesData] = useState<
    VenuesResponse | ErrorResponse | undefined
  >();
  const [venueSummaryData, setVenueSummaryData] = useState<
    VenueSummaryResponse | ErrorResponse | undefined
  >();
  const [venueHomeData, setVenueHomeData] = useState<VenueHome | undefined>();

  // Venues
  const [venues, setVenues] = useState<CombinedVenue[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<CombinedVenue>();

  // Services
  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<number>(-1);

  // Sales Area
  const [selectedSalesArea, setSelectedSalesArea] = useState<VenueSalesArea>();

  const fetchVenues = (venueId?: number) => {
    if (!fetchingVenues) {
      setFetchingVenues(true);
      setVenuesData(undefined);

      const getVenuesConfig = getVenues();

      const venueOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        headers: getVenuesConfig.headers,
        data: requestBodyFormatter({
          method: getVenuesConfig.method,
          ...getVenuesConfig.body,
          siteId: venueId ?? undefined,
        }),
      };

      axios(venueOptions)
        .then((response) => {
          setVenuesData(response.data);
        })
        .finally(() => {
          setFetchingVenues(false);
        });
    }
  };

  const fetchVenueSummary = (coordinates?: Coordinates, venueId?: number) => {
    if (!fetchingVenueSummary) {
      setFetchingVenueSummary(true);
      setVenueSummaryData(undefined);

      const getVenueSummaryConfig = getVenueSummary();

      const coordinatesParams = coordinates ?? undefined;
      const venueSummaryOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        headers: getVenueSummaryConfig.headers,
        data: requestBodyFormatter({
          method: getVenueSummaryConfig.method,
          ...getVenueSummaryConfig.body,
          ...coordinatesParams,
          siteId: venueId ?? undefined,
        }),
      };

      axios(venueSummaryOptions)
        .then((response) => {
          setVenueSummaryData(response.data);
        })
        .finally(() => {
          setFetchingVenueSummary(false);
        });
    }
  };

  const fetchVenueHome = (venueId: number) => {
    if (!fetchingVenueHome) {
      setFetchingVenueHome(true);
      setVenueHomeData(undefined);

      const getVenueHomeConfig = getVenueHome();

      const venueHomeOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        headers: getVenueHomeConfig.headers,
        data: requestBodyFormatter({
          method: getVenueHomeConfig.method,
          ...getVenueHomeConfig.body,
          siteId: venueId,
        }),
      };

      axios(venueHomeOptions)
        .then((response) => {
          const data = response.data as GetHomeResponse;
          if (data.response === 'OK') {
            const venueData: VenueHome = {
              banners: data.banners,
              header: data.header,
              venueId,
            };
            setVenueHomeData(venueData);
          }
        })
        .finally(() => {
          setFetchingVenueHome(false);
        });
    }
  };

  const handleVenueDataError = () => {
    const venuesError = venuesData as ErrorResponse;
    const venueSummaryError = venueSummaryData as ErrorResponse;
    const codeToReport = venueSummaryError?.code ?? venuesError?.code;
    const errorToReport =
      venueSummaryError?.detail ??
      venueSummaryError?.message ??
      venuesError?.detail ??
      venuesError?.message;

    if (codeToReport && errorToReport) {
      dispatch(
        addNotification(
          `There was an error loading venues. ${errorToReport} (${codeToReport})`,
          'danger',
        ),
      );
      setVenues([]);
      setServices([]);
    }
  };

  useEffect(() => {
    if (
      !fetchingVenues &&
      !fetchingVenueSummary &&
      isGetVenueSummaryResponse(venueSummaryData) &&
      isVenueResponse(venuesData)
    ) {
      const combinedVenues: CombinedVenue[] = [];

      // TODO - this redirects if no venues are returned
      // Not sure why but we should look to just handle it on the sales area page.
      if (venuesData.venues.length === 0 && pathname.includes('sales-area')) {
        history.push('/');
      }

      venuesData?.venues.forEach((venue) => {
        const foundVenue = venueSummaryData?.venues.find(
          (vs) => vs.id == venue.id,
        );

        if (foundVenue) {
          const mergedVenue = { ...venue, ...foundVenue };
          // We use the venue obj for most info but for contact details the summary call gives us more info
          mergedVenue.contactDetails = {
            ...venue.contactDetails,
            ...foundVenue.contactDetails,
          };
          combinedVenues.push(mergedVenue);
        }
      });

      setVenues(combinedVenues);
      setServices(venuesData.services);

      if (combinedVenues.length === 1) {
        setSelectedVenue(combinedVenues[0]);
      }
    } else {
      handleVenueDataError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venuesData, venueSummaryData]);

  return (
    <VenueContext.Provider
      value={{
        initialised: true,
        venues,
        venueHomeData,
        selectedVenue,
        setSelectedVenue,
        fetchVenues,
        fetchVenueSummary,
        fetchVenueHome,
        isLoadingVenues:
          fetchingVenues || fetchingVenueSummary || fetchingVenueHome,
        services,
        selectedService,
        setSelectedService,
        selectedSalesArea,
        setSelectedSalesArea,
      }}
    >
      {children}
    </VenueContext.Provider>
  );
};
