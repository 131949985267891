import { useState, useEffect, useRef } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-bootstrap/lib/Navbar';
import { Helmet } from 'react-helmet-async';
import { Nav, Navbar } from 'react-bootstrap';
import {
  LoggedOutHeader,
  LoggedInHeader,
  Logo,
  VenueTitle,
  ServiceHeader,
} from 'core/components/Header';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmVenueModal } from 'venue/components/ConfirmVenueModal';
import ReactDOM from 'react-dom';
import { setDefaultThemeColour, removeNotification } from 'core/actions';
import { selectDefaultThemeColour } from 'core/selectors';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useBasket } from 'contexts/BasketContext';
import { useHistory } from 'react-router';
import { useVenueSearch } from 'hooks/useVenueSearch';
import { useServices, useVenues } from 'contexts/VenueContext';
import { useVouchers } from 'contexts/VoucherContext';
import { useCheckout } from 'contexts/CheckoutContext';
import { useGlobalUser } from 'contexts/UserContext';

export const Header: React.FC = () => {
  const {
    accountDeletionOnly,
    disableSearch,
    env,
    googleMapsApiKey,
    homepage,
    removeBackLink,
    searchPredictionEnabled,
  } = useConfig();
  const { homeText, selectVenue } = usePhrases();
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoggedIn } = useGlobalUser();
  const { removeAllGiftCards, redeemedGiftCards } = useGiftCard();
  const { removeAllRewards, redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { setSearchTerm } = useVenueSearch();
  const { setSelectedVenue } = useVenues();
  const { setSelectedService } = useServices();
  const { basket, clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();
  const { redeemedVouchers, voidAllVouchers } = useVouchers();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [showConfirmVenueModal, setShowConfirmVenueModal] =
    useState<boolean>(false);

  const headerRef = useRef<Navbar>(null);

  const defaultThemeColour = useSelector(selectDefaultThemeColour);

  const basketIsEmpty = basket.length === 0;
  const hasLoyaltyRewards = redeemedLoyaltyRewards.length > 0;
  const hasGiftCards = redeemedGiftCards.length > 0;

  useEffect(() => {
    if (headerRef?.current !== null) {
      //Is there a better way of doing this? I'd like to not use findDOMNode, but
      //class component refs are making it hard!

      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(headerRef.current);
      const theme = getComputedStyle(element as Element);
      dispatch(setDefaultThemeColour(theme.backgroundColor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchOnRedirect = () => {
    setSelectedVenue(undefined);
    setSelectedService(-1);
    setSearchTerm('');
    dispatch(removeNotification());
  };

  const redirectToFF = () => {
    history.push('/ff');
  };

  const redirectToVenues = () => {
    if (!basketIsEmpty) {
      setShowConfirmVenueModal(true);
    } else if (disableSearch) {
      window.open(homepage, '_self');
    } else {
      history.push('/venues/list');
      dispatchOnRedirect();
    }
  };

  const handleConfirmVenueChange = () => {
    if (!basketIsEmpty) {
      if (redeemedVouchers.length > 0) {
        voidAllVouchers();
      }

      if (hasGiftCards) {
        removeAllGiftCards();
      }

      if (hasLoyaltyRewards) {
        removeAllRewards();
      }
    }

    disableSearch === true
      ? window.open(homepage, '_self')
      : history.push('/venues/list');
    clearBasket();
    clearCheckout();
    setShowConfirmVenueModal(false);
    dispatchOnRedirect();
  };

  const handleDismissVenueChange = () => {
    setShowConfirmVenueModal(false);
  };

  return (
    <Navbar
      className="header"
      expanded={expanded}
      data-testid="header-nav-bar"
      ref={headerRef}
    >
      {searchPredictionEnabled && (
        <Helmet>
          <script
            async
            src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places&loading=async`}
            type="text/javascript"
          ></script>
        </Helmet>
      )}
      {defaultThemeColour && (
        <Helmet>
          <meta name="theme-color" content={defaultThemeColour} />
        </Helmet>
      )}
      <Navbar.Header className="flex">
        <Logo />
        {!accountDeletionOnly ? (
          <Navbar.Toggle
            onClick={() => setExpanded(!expanded)}
            className="flex-left"
          />
        ) : null}
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav className="no-bottom-margin">
          {!removeBackLink && !accountDeletionOnly ? (
            <li role="presentation" key="backLink">
              <a
                href={homepage}
                className="header-link"
                onClick={() => setExpanded(false)}
              >
                <MdArrowBack className="react-icon" /> {homeText}
              </a>
            </li>
          ) : null}
          {!accountDeletionOnly ? (
            <li role="presentation" key="selectButton">
              <Link
                className="header-link header-link-left-padding"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded(false);
                  redirectToVenues();
                }}
                data-testid="header-select-venue-link"
              >
                {selectVenue}
              </Link>
            </li>
          ) : null}
          {(env === 'qa1' || env === 'qa2' || env === 'development') && (
            <li role="presentation" key="FFButton">
              <Link
                className="header-link header-link-left-padding"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  redirectToFF();
                }}
                data-testid="ff-link"
              >
                FF
              </Link>
            </li>
          )}
        </Nav>
        {isLoggedIn ? (
          <LoggedInHeader closeNav={() => setExpanded(false)} />
        ) : (
          <LoggedOutHeader closeNav={() => setExpanded(false)} />
        )}
      </Navbar.Collapse>
      <VenueTitle />
      <ServiceHeader />
      <ConfirmVenueModal
        show={showConfirmVenueModal}
        proceed={handleConfirmVenueChange}
        dismiss={handleDismissVenueChange}
      />
    </Navbar>
  );
};
