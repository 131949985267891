import axios, { AxiosInstance } from 'axios';
import JSONBigInt from 'json-bigint';

interface AxiosInstanceProps {
  pmbAxios: AxiosInstance;
}

export const useAxiosInstance = (): AxiosInstanceProps => {
  const pmbAxios = axios.create({
    transformResponse: [
      (data) => {
        try {
          return JSONBigInt({ storeAsString: true }).parse(data);
        } catch (err) {
          throw new Error('Failed to parse response data');
        }
      },
    ],
  });

  return { pmbAxios };
};
