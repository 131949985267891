import { useEffect } from 'react';
import { Panel, Button } from 'react-bootstrap';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { addressToString } from 'venue/utils';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useDispatch } from 'react-redux';
import { setSelectedTipPreset, setTipAmount } from 'checkout/actions';
import { WLWOLocationState } from 'types/models/WLWOLocationState';
import { EmailConfirmation } from './components';
import { SelectedServiceInformation } from 'checkout/components/SelectedServiceInformation';
import { ServiceType } from 'types/models';
import { useBasket } from 'contexts/BasketContext';
import { usePhrases } from 'contexts/ConfigContext';
import { getOrderLocationPhrase } from 'utils';
import { useAllergenPrompt } from 'contexts/AllergenContext';
import { useTables } from 'contexts/TableContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { useCheckout } from 'contexts/CheckoutContext';

interface ParamsProps {
  guestEmail?: string;
}

export const Complete: React.FC = () => {
  const { guestEmail } = useParams<ParamsProps>();
  const dispatch = useDispatch();
  const { venueSalesAreaFormat } = usePhrases();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { clearBasket } = useBasket();
  const { setHasPromptBeenShown } = useAllergenPrompt();
  const { selectedTable, setSelectedTable } = useTables();
  const { setSelectedTimeslot } = useTimeslots();
  const { clearCheckout, orderId } = useCheckout();

  const location = useLocation<WLWOLocationState>();
  const history = useHistory();

  const orderLocationPhrase = getOrderLocationPhrase(
    venueSalesAreaFormat,
    selectedVenue,
    selectedSalesArea,
  );

  const clearCheckoutAndOrder = () => {
    clearCheckout();
    clearBasket();
    setSelectedTimeslot(undefined);
    setSelectedTable(undefined);
    setHasPromptBeenShown(false);

    dispatch(setSelectedTipPreset(0));
    dispatch(setTipAmount(0));
  };

  const onButtonClick = () => {
    if (selectedService === ServiceType.OrderAndPay) {
      history.push(
        `/venue/${selectedVenue?.id}/sales-area/${selectedSalesArea?.id}/service/${selectedService}/table/${selectedTable?.number}`,
      );
    } else {
      history.push(
        `/venue/${selectedVenue?.id}/sales-area/${selectedSalesArea?.id}/service/${selectedService}`,
      );
    }
  };

  useEffect(() => {
    return () => {
      clearCheckoutAndOrder();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (!(location?.state?.from?.pathname === '/checkout' && orderId)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="checkout-complete-container" data-testid="order-complete">
      <Panel>
        <h2>Complete</h2>
        <p>
          Thank you for your order at <strong>{orderLocationPhrase}</strong>
        </p>
        <p>
          {selectedVenue?.address &&
            addressToString(selectedVenue?.address, ', ')}
        </p>
        <p
          className="order-number"
          data-testid="order-number"
        >{`Order #${orderId}`}</p>
        <SelectedServiceInformation />
        <EmailConfirmation guestEmail={guestEmail} />
        <Button
          className="btn btn-primary"
          bsStyle="primary"
          onClick={() => onButtonClick()}
        >
          Order again
        </Button>
      </Panel>
    </div>
  );
};
