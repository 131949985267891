import { useConfig } from 'contexts/ConfigContext';
import { Password } from 'core/components/RHF/FieldTemplates';
import { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Field, FormsConfig } from 'types/models/Forms';
import { addNotification } from 'core/actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useUserSettings } from 'contexts/UserContext';

interface FormProps {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

interface PasswordChangeFormProps {
  formData: FormsConfig;
}

export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({
  formData,
}) => {
  const dispatch = useDispatch();
  const { prodCaptchaSitekey } = useConfig();
  const { changePassword } = useUserSettings();

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const methods = useForm<FormProps>({
    mode: 'all',
  });

  const [isChangingPassword, setIsChangingPassword] = useState(false);

  // What is the min password length to use as a fallback?
  // For the old password should we apply any validation?
  // For the new - if we can't get the field from the config do allow the user to continue?
  const oldPasswordField: Field = {
    label: 'Old Password',
    min: 8,
    name: 'oldPassword',
    placeholder: 'Old Password',
    required: true,
    type: 'password',
  };

  const getPasswordField = (): Field | undefined => {
    return formData?.register?.find((field) => field.type === 'password');
  };

  const newPasswordField = getPasswordField();

  const handleChangePassword: SubmitHandler<FormProps> = async (values) => {
    if (recaptchaRef.current) {
      setIsChangingPassword(true);
      const token = await recaptchaRef.current.executeAsync();
      axios.post(`/recaptcha?response=${token}`).then((res) => {
        if (res.data.success) {
          const { oldPassword, newPassword, newPasswordConfirm } = values;
          changePassword(oldPassword, newPassword, newPasswordConfirm);
        } else {
          dispatch(
            addNotification(
              'Something went wrong, please try again.',
              'danger ',
            ),
          );
        }
      });
    }
    setIsChangingPassword(false);
    recaptchaRef.current?.reset();
  };

  return (
    <>
      {newPasswordField ? (
        <FormProvider {...methods}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={
              prodCaptchaSitekey || '6LdqtnoUAAAAAPuSlZ2wVCCtmQGYNd-PvSfaFVQw'
            }
          />
          <Password
            field={oldPasswordField}
            shouldValidatePasswordStrength={false}
          />
          <Password
            field={{
              ...newPasswordField,
              name: 'newPassword',
              label: 'New Password',
              placeholder: 'New Password',
            }}
          />
          <Password
            field={{
              ...newPasswordField,
              name: 'newPasswordConfirm',
              label: 'Confirm Password',
              placeholder: 'Confirm Password',
            }}
            matchFieldName="newPassword"
          />
          <Button
            bsStyle="primary"
            type="submit"
            onClick={methods.handleSubmit(handleChangePassword)}
            disabled={isChangingPassword}
          >
            Save
          </Button>
        </FormProvider>
      ) : (
        <div>Something went wrong, please try again later.</div>
      )}
    </>
  );
};
